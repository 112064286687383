var render = function render(){var _vm=this,_c=_vm._self._c;return _c('router-link',{staticClass:"tourItem",attrs:{"to":{
    name: 'TourInfo',
    params: {
      slug: _vm.propsData.slug,
      locale: _vm.$i18n.locale == 'en' ? null : _vm.$i18n.locale
    }
  }}},[_c('div',{staticClass:"l"},[_c('div',{staticClass:"imgWrp"},[_c('img',{attrs:{"src":_vm.path(_vm.propsData.image),"alt":""}})])]),_c('div',{staticClass:"r"},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.propsData.name))]),_c('div',{staticClass:"infoText"},_vm._l((_vm.tourAttrs),function(item,idx){return _c('p',{key:idx,staticClass:"country"},[_c('i',{class:item.attr_icon}),_vm._v(" "+_vm._s(item.value)+" ")])}),0),_c('p',{staticClass:"desc"},[_vm._v(" "+_vm._s(_vm.propsData.description)+" ")]),_c('router-link',{staticClass:"routeIcon",attrs:{"to":{ name: 'TourInfo', params: { slug: _vm.propsData.slug } }}},[_c('i',{staticClass:"icon ic-arrow"})])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }